export default {
    alertSwitchSetting: `
**Nie wyświetlaj komunikatów o błędach w wpisach**

Przy zaznaczonej opcji WykopGPT nie będzie informował o błędach, np. o braku tokenów we wpisie. 
    `,
    botDisabledSetting: `
**Uśpij bota**

Wyłącza bota dla Twojego konta. Bot nie będzie reagował na wołanie.
`,
    imageModelSelectSetting: `
**Model do generowania obrazów:** 

Domyślny model, który będzie używany przez bota do generowania grafik.

**Dalle-2** posiada ograniczone możliwości generowania obrazów oraz **wymaga użycia języka angielskiego** w opisie aby uzykać najlepszy wynik. Jego zaletą jest jego cena - zużywa dwa razy mniej tokenów niż Dalle-3

**Dalle-3** to najnowszy i najbardziej zaawansowany dostępny model do generowania grafiki
    `,
    modelSelectSetting: `
**Model tekstowy AI:**   

Wybrany model, który będzie używany przez bota.
`,
    switchContextSetting: `
**Dołączaj kontekst całego wpisu**    
    
Chat domyślnie odpowiada jedynie na komentarz, w którym jest wołany, nie zna całego kontekstu wpisu. Zaznaczając tę opcję przy każdym wołaniu chata, dostanie on całą historię komentarzy we wpisie dzięki czemu lepiej będzie rozumiał kontekst.

**Uwaga!** Zaznaczenie tej opcji drastycznie zwiększy zużycie tokenów, dlatego **nie jest** zalecane przy modelu GPT-4
`,
};
