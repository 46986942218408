export default {
    about: `
## Wstęp

Wykop-gpt został stworzony aby służyć użytkownikom Wykopu i pozwolić im na korzystanie z narzędzia ChatGPT bez konieczności rejestracji na OpenAI i opłacania miesięcznej subskrypcji OpenAI Plus. Każdy użytkownik ma do dyspozycji **1500** darmowych tokenów do wykorzystania w ramach zapytań do ChatGPT w wersji 3.5.  

## Wsparcie projektu

**Aby uzyskać kolejne tokeny**, wesprzyj projekt, [kliknij i postaw mi wirtualną kawę w serwisie BuyCoffee](https://buycoffee.to/sheppard30). Wyrazem mojej wdzięczności, (jeśli sobie zażyczysz) będzie dodatkowe zasilenie Twojego konta tokenami, dostęp do najnowszych feature'ów, w tym **ChataGPT w wersji 4 Turbo (ulepszona wersja GPT4, niedostępna w subskrypcji OpenAI Plus dla "zwykłych" "komercyjnych" użytkowników)**. ( ͡° ͜ʖ ͡°) Pamiętaj, jeżeli chcesz otrzymać tokeny, **w formularzu na stronie BuyCoffee zaznacz opcję *Chcę pozostawić wiadomość dla Twórcy* i w polu, które się pojawi wpisz swój nick z Wykopu**, tak abym wiedział komu odblokować dostęp do najnowszych funkcjonalności i doładować konto.

**Pamiętaj!** Twoje wsparcie nie jest płatnością za usługę a dobrowolną darowizną. Nie ma żadnego oficjalnego przelicznika co do ilości tokenów / PLN natomiast chcę abyś pamiętał(a), że celem projektu **nie** jest zarabianie na nim a to aby, dzięki pieniądzom z darowizn móc **sfinansować działanie bota** oraz dać Wam możliwość wygodnego **korzystania z GPT-4 Turbo zintegrowanego z Wykopem** na korzystnych dla Was warunkach.


## Dlaczego warto wspomóc projekt?

1. Model, do którego otrzymasz dostęp (o ile zechcesz) po wpłaceniu darowizny - **GPT 4 Turbo** jest znacznie lepszy od "darmowej" wersji 3.5:

- jego baza danych informacji jest niemal współczesna i stale aktualizowana w kolejnych releasach

- znacznie lepiej rozumie czego od niego oczekujasz, nie zmyśla i potrafi przeszukać Internet w poszukiwaniu informacji o które pytasz

- jest szybszy i stabilniejszy

2. Otrzymasz dostęp do generowania obrazów za pomocą modeli **Dall-e-2** oraz **Dall-e-3** bezpośrednio na wykopie.

3. Otrzymasz ekwiwalent Twojego wsparcia w tokenach do wykorzystania.

4. Przyczynisz się do rozwoju ciekawego projektu, który w przyszłości może stać się naprawdę użyteczny i dawać sporo funu :)


## Porady od autora

1. Bądź konkretny, tak aby bot poprawnie zrozumiał o co pytasz i udzielił najbardziej satysfakcjonującej Cię odpowiedzi.

2. Poproś bota żeby udzielał zwięzłych odpowiedzi aby zmniejszyć zużycie tokenów.

3. Nie przeklinaj, nie prowokuj, nie wpisuj treści, które mogą być zawaklifikowane jako niedozwolone. Bot odrzuci tego typu pytania natomiast nadal zapłacisz za tokeny, które wysłałeś.

4. Nie używaj tagów, zdjęć, linków i innych tego typu treści w pytaniach, których bot nie rozumie albo może źle zinterpretować.

5. Nie pytaj o głupoty jak pasta o serwerowni czy o Tigera Bonzo - odpowiedź bota raczej Cię nie zadowoli.
    

## Uwagi techniczne

### Limity API

Każde z API, z których korzysta bot posiada swoje limity przyjmowanych requestów w jednostce czasu, np. wykopie zależy to od [rodzaju konta](https://wykop.pl/faq/konto#limity-na-koncie). Z tego powodu może się zdarzyć, że poprawne zapytanie nie zostanie przetworzone. W miarę możliwości, bot będzie zwracał informacje o problemie bądź próbował ponowić operację, jednak w przypadku odbicia się od wyżej wspomnianego limitu komentarzy na Wykopie, może się zdarzyć, że bot nie odpowie na pytanie użytkownika. Tokeny w takim wypadku nie zostaną pobrane z konta a odpowiedź pojawi się z opóźnieniem lub w ogóle.
    `,
};
