import { AppConfigResponseDto } from '../../api/types';
import { Config } from '../../types';
import { ResponseError } from '../errors';

export const mapConfigResponse = (response: AppConfigResponseDto): Config => {
    if (!response.data) {
        throw new ResponseError();
    }

    const { data } = response;

    return {
        imageModels: data.imageModels ?? [],
        models: data.models ?? [],
    };
};
