import { Theme, extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
    breakpoints: {
        base: '0em',
        lg: '1200px',
    },
    styles: {
        global: {
            body: {
                backgroundColor: 'gray.800',
                color: 'white',
            },
        },
    },
}) as Theme;
