import { Box, HStack, Icon, Text, VStack } from '@chakra-ui/react';

import { Role } from '../api/types';
import { ReactComponent as IconAvatar } from '../assets/icons/icon-avatar.svg';
import { ReactComponent as IconCoin } from '../assets/icons/icon-coin.svg';

import { useAuthorization } from './AuthorizationContext';

export const Avatar = () => {
    const { user } = useAuthorization();

    return (
        <HStack alignItems="center" alignSelf="flex-start">
            <Box
                border="2px solid"
                borderRadius="4px"
                height="48px"
                width="48px"
            >
                <Icon as={IconAvatar} height="100%" width="100%" />
            </Box>
            <VStack alignItems="flex-start" gap="0">
                <HStack>
                    <Text>{user?.username}</Text>
                    {user?.role === Role.Supporter && (
                        <Text>(wspierający)</Text>
                    )}
                </HStack>
                <HStack alignItems="center" fill="gray.500">
                    <Icon
                        as={IconCoin}
                        height="24px"
                        title="Tokeny"
                        width="24px"
                    />
                    <Text color="gray.500">{user?.tokens}</Text>
                </HStack>
            </VStack>
        </HStack>
    );
};
