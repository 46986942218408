import { sessionStorageTokenKey } from '../../config';

export const getAccessToken = () => {
    return sessionStorage.getItem(sessionStorageTokenKey) || '';
};

export const setAccessToken = (token: string) => {
    sessionStorage.setItem(sessionStorageTokenKey, token);
};

export const deleteAccessToken = () => {
    sessionStorage.removeItem(sessionStorageTokenKey);
};
