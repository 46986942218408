/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ConnectResponseDto {
    data: string;
}

export enum Role {
    Standard = 'standard',
    Supporter = 'supporter',
    Admin = 'admin',
}

export enum Model {
    Gpt3 = 'gpt-3',
    Gpt4 = 'gpt-4',
}

export enum ImageModel {
    DallE2 = 'dall-e-2',
    DallE3 = 'dall-e-3',
    None = 'none',
}

export interface UserDto {
    id: number;
    username: string;
    tokens: number;
    disabled: number;
    role: Role;
    settingContext: boolean;
    settingModel: Model;
    settingImageModel: ImageModel;
    settingCommunicatesInEntry: boolean;
    settingBotDisabled: boolean;
}

export interface UserMeResponseDto {
    data: UserDto;
}

export interface UpdateSettingsBodyDto {
    communicatesInEntry: boolean;
    context: boolean;
    botDisabled: boolean;
    model: Model;
    imageModel: ImageModel;
}

export interface UpdateSettingsResponseDto {
    data: UserDto;
}

export interface UpdateUserBodyDto {
    disabled: boolean;
    tokens: number;
    role: Role;
    model: Model;
    imageModel: ImageModel;
}

export interface UpdateUserResponseDto {
    data: UserDto;
}

export interface UserSearchResponseDto {
    data: UserDto[];
}

export interface AppConfigDto {
    models: Model[];
    imageModels: ImageModel[];
}

export interface AppConfigResponseDto {
    data: AppConfigDto;
}

export interface ImageDto {
    id: number;
    prompt: string;
    /** @format date-time */
    date: string;
}

export interface GetImagesResponseDto {
    data: ImageDto[];
}

export interface GetImageBase64ResponseDto {
    data: string;
}
