import {
    SystemStyleObject,
    Text,
    List,
    VStack,
    Icon,
    ListItem,
    As,
    useToken,
    Button,
    useToast,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';

import { ConnectResponseDto, Role } from '../api/types';
import { ReactComponent as IconAdmin } from '../assets/icons/icon-admin.svg';
import { ReactComponent as IconFAQ } from '../assets/icons/icon-faq.svg';
import { ReactComponent as IconUserSettings } from '../assets/icons/icon-gear.svg';
import { ReactComponent as IconImage } from '../assets/icons/icon-image.svg';
import { ReactComponent as IconInfo } from '../assets/icons/icon-info.svg';
import { ReactComponent as IconLogin } from '../assets/icons/icon-login.svg';

import { useFetch } from '../hooks';

import { mapConnectResponse } from '../utils';

import { useAuthorization } from './AuthorizationContext';
import { Avatar } from './Avatar';

const listItemBaseStyles: SystemStyleObject = {
    '&:hover': {
        backgroundColor: 'gray.700',
    },
    alignItems: 'center',
    borderRadius: '4px',
    display: 'flex',
    gap: '8px',
    marginBottom: '8px',
    padding: '4px 8px',
    position: 'relative',

    transition: '0.3s background-color',
};

const activeListItemStyles: SystemStyleObject = {
    '&:before': {
        backgroundColor: 'blue.400',
        borderRadius: '2px',
        content: '""',
        height: '80%',
        left: '-8px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '4px',
    },
    backgroundColor: 'gray.700',
};

export const Menu = () => {
    const toast = useToast();
    const iconFill = useToken('colors', 'gray.400');
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { authorized, user, logout } = useAuthorization();
    const { get: login } = useFetch<ConnectResponseDto, string>({
        cache: true,
        endpoint: 'auth/connect',
    });

    const handleLogin = async () => {
        try {
            const url = await login({ mapResponse: mapConnectResponse });
            window.location.href = url;
        } catch {
            toast({
                duration: 5000,
                status: 'error',
                title: 'Problem z zalogowaniem się. Spróbuj ponownie.',
            });
        }
    };

    const handleLogout = () => {
        logout();
    };

    const menuItems = [
        {
            icon: IconInfo,
            label: 'O projekcie',
            path: '/',
        },
        {
            icon: IconFAQ,
            label: 'FAQ',
            path: '/faq',
        },
    ];

    if (authorized) {
        menuItems.push(
            {
                icon: IconImage,
                label: 'Wygenerowane obrazy',
                path: '/images',
            },
            {
                icon: IconUserSettings,
                label: 'Ustawienia',
                path: '/settings',
            }
        );

        if (user?.role === Role.Admin) {
            menuItems.push({
                icon: IconAdmin,
                label: 'Panel administracyjny',
                path: '/admin',
            });
        }
    }

    return (
        <VStack alignItems="stretch" gap="32px" paddingX="8px">
            {authorized && <Avatar />}
            <List>
                {menuItems.map(({ label, icon, path }) => {
                    const isActive = path === pathname;

                    const listItemStyles: SystemStyleObject = {
                        ...listItemBaseStyles,
                        ...(isActive && activeListItemStyles),
                    };

                    return (
                        <ListItem
                            key={label}
                            role="button"
                            sx={listItemStyles}
                            onClick={() => navigate(path)}
                        >
                            <Icon as={icon as As} fill={iconFill} />
                            <Text>{label}</Text>
                        </ListItem>
                    );
                })}
            </List>
            {authorized ? (
                <Button
                    colorScheme="telegram"
                    leftIcon={<IconLogin fill="#fff" height={24} width={24} />}
                    onClick={handleLogout}
                >
                    Wyloguj
                </Button>
            ) : (
                <Button
                    colorScheme="telegram"
                    leftIcon={<IconLogin fill="#fff" height={24} width={24} />}
                    onClick={handleLogin}
                >
                    Zaloguj z WykopConnect
                </Button>
            )}
        </VStack>
    );
};
