export default {
    faq: `
**1. Jak użyć WykopGPT?**
Wystarczy zawołać konto bota, np: "@wykop-gpt kto jest najpotężniejszym programistą i dlaczego Sheppard?". Możesz również wcześniej zacytować komentarz innego użytkownika aby nadać kontekst.

Aby wygenerować obraz (opcja tylko dla wspierających) możesz otagować wpis/komentarz tagiem "#wykopgptgrafika" **lub** użyć wyrażeń kluczowych (case insensitive) jak:
- wygeneruj + (obraz|grafike|grafikę|zdjecie|zdjęcie), np. "Wygeneruj zdjęcie typowego wykopka".
- generate + (picture|image), np. "Generate picture of a car"


**2. Ile wynosi przydział darmowych tokenów? Co gdy się skończą?**
Limit wstępnie ustawiłem na 1500 tokenów. Gdy skończą się, liczba zapytań jest limitowana do 1 na 30 minut. Ilość ta wynika z faktu, że jako autor tego narzędzia ponoszę szereg kosztów wynikających z udostępnienia tej funkcjonalności, m.in. koszty zapytań do OpenAI (tokeny które dostajecie nie są darmowe dla mnie) czy serwera, nie wspominając już o poświęconym prywatnym czasie na jej tworzeniu. Moim pomysłem jest finansowanie działania narzędzia jedynie z dobrowolnych wpłat użytkowników Wykopu i innych darczyńców, dlatego przyszłość bota i dalszy jego rozwój są uzależnione od tego czy/ile do tego projektu będę musiał dokładać z własnej kieszeni :)


**3. Ile wynosi doładowanie tokenów po wpłacie darowizny?**
To zależy od kwoty, jest to z grubsza odpowiednikiem ceny jaką płacę OpenAI ale jest również wielce prawdopodobnym, że kwota ta będzie ruchoma z uwagi na monitorowanie kosztów, natomiast chciałbym aby stali darczyńcy mieli korzystniejsze warunki.


**4. Ile to jest 1500 tokenów? Dużo, Mao?**
Możesz wejść na stronę https://platform.openai.com/tokenizer i sprawdzić sam wpisując jakieś zdanie w polu tekstowym. Pamiętaj, że ilość tokenów pobranych z Twojego konta to tokeny składające się na sumę pytania oraz odpowiedzi bota.


**5. Ile będę czekał(a) na doładowanie tokenów gdy zdecyduję się na wsparcie?**
Na początku nie będzie to proces zautomatyzowany, więc będę robił to w swoim wolnym czasie, możliwie szybko. W zależności od tego oraz zainteresowania, może to być do max kilku dni od czasu pojawienia się darowizny w systemie BuyCoffee. Po doładowaniu konta dostaniesz PW na Wykopie z informacją o ilości przyznanych dodatkowych tokenów.


**6. Co jeżeli z jakiegoś powodu bot nie odpowie na moje pytanie? Czy tokeny znikną?**
Tokeny są odejmowane dopiero po wysłaniu odpowiedzi na Wykop. Jeżeli nie otrzymałeś odpowiedzi, nie zostały pobrane dla Twojego konta.


**7. Dostałem informację, że moje pytanie zawiera potencjalnie niedozwolone treści. Co to oznacza?**
Twoje pytanie zostało zakwalifikowane przez AI jako potencjalnie naruszające regulamin Wykopu oraz OpenAI i nie zostało przesłane do ChataGPT. W takim przypadku Twoje tokeny zostaną pobrane a powtarzanie się takich sytuacji, może skończyć się zablokowaniem dostępu do usługi dla Twojego konta.


**8. Co jeśli skończyły mi się tokeny?**
W przypadku braku tokenów limit wysłanych zapytań wynosi jedno na 30 min. W każdej chwili możesz wspomóc projekt i otrzymać tokeny aby ominąć limit.


**9. Czy bot ogarnia pastę o serwerowni?**
Nie, tej pasty o której myślicie nie ogarnia, więc szkoda marnować tokeny na zadawanie tego typu pytań. :) Natomiast w najbliższej przyszłości planuję uruchomić bota, którego będzie można uczyć.


**10. Jaka jest różnica między modelami?**
Darmowa wersja GPT-3.5 to "najprostszy" model, znacznie słabszy niż jego ulepszone wersje GPT-4 oraz najlepszy - GPT-4 Turbo. Zwykły GPT-4 jest dostępny w płatnej subskrypcji OpenAI Plus za 20$ miesięcznie natomiast GPT-4 Turbo (który udostępniam wspierającym) jest dostępny tylko jako usługa API.
Jeżeli chodzi o modele do generowania grafiki, Dalle2 rozumie jedynie język angielski i nie zwraca żadnego opisu dla zdjęcia. Same grafiki są też gorszej jakości. Dalle3 to najbardziej zaawansowany model, potrafi tworzyć obrazy na podstawie prompta w języku polskim i lepiej rozumie zadany przez Ciebie opis

    `,
};
