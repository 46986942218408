import {
    TableContainer as ChakraTableContainer,
    TableContainerProps as ChakraTableContainerProps,
} from '@chakra-ui/react';

import { usePlatform } from '../../hooks';

export const TableContainer = (props: ChakraTableContainerProps) => {
    const { isDesktop } = usePlatform();

    return (
        <ChakraTableContainer
            width={isDesktop ? '100%' : 'calc(100vw - 40px)'}
            {...props}
        />
    );
};
