import { Box, SystemStyleObject, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { Menu } from './Menu';

const hamburgerLine: SystemStyleObject = {
    backgroundColor: 'gray.200',
    borderRadius: '2px',
    height: '6px',
    transition: 'transform 0.3s, opacity 0.3s',
    width: '100%',
};

export const HamburgerMenu = () => {
    const [open, setOpen] = useState(false);
    const location = useLocation();

    const handleClick = () => {
        setOpen((state) => !state);
    };

    useEffect(() => {
        setOpen(false);
    }, [location.pathname]);

    const hamburgerStyles: SystemStyleObject = {
        height: '56px',
        justifyContent: 'space-between',
        padding: '8px',
        position: 'relative',
        right: '4px',
        top: '4px',
        width: '64px',
        zIndex: 1,
        ...(open && {
            'div:nth-child(1)': {
                transform: 'translateY(17px) rotate(45deg)',
            },
            'div:nth-child(2)': {
                transform: 'rotate(-45deg)',
            },
            'div:nth-child(3)': {
                opacity: 0,
            },
        }),
    };

    const layerStyles: SystemStyleObject = {
        backgroundColor: 'gray.900',
        height: '100vh',
        padding: '100px 20px',
        position: 'fixed',
        transform: 'translateX(100%)',
        transition: 'transform 0.5s',
        width: '100vw',
        ...(open && {
            transform: 'translateX(0)',
        }),
    };

    return (
        <>
            <VStack role="button" sx={hamburgerStyles} onClick={handleClick}>
                <Box sx={hamburgerLine} />
                <Box sx={hamburgerLine} />
                <Box sx={hamburgerLine} />
            </VStack>
            <Box sx={layerStyles}>
                <Menu />
            </Box>
        </>
    );
};
