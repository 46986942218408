import {
    ImageModel,
    Model,
    Role,
    UserSearchResponseDto,
} from '../../api/types';
import { User } from '../../types';
import { ResponseError } from '../errors';

export const mapUserSearchResponse = (
    response: UserSearchResponseDto
): User[] => {
    if (!response.data) {
        throw new ResponseError();
    }

    const { data } = response;

    return data.map((user) => ({
        disabled: !!user.disabled ?? false,
        id: user.id ?? 0,
        role: user.role ?? Role.Standard,
        settingBotDisabled: user.settingBotDisabled ?? false,
        settingCommunicatesInEntry: user.settingCommunicatesInEntry ?? false,
        settingContext: user.settingContext ?? false,
        settingImageModel: user.settingImageModel ?? ImageModel.None,
        settingModel: user.settingModel ?? Model.Gpt3,
        tokens: user.tokens ?? 0,
        username: user.username ?? '',
    }));
};
