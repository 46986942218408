import { useToast } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { ImageModel, Model, UpdateSettingsResponseDto } from '../../api/types';
import { useAuthorization, useConfig } from '../../components';
import { useFetch } from '../../hooks';
import { User } from '../../types';
import { mapUserResponse } from '../../utils';

export type FormData = {
    communicatesInEntry: boolean;
    context: boolean;
    botDisabled: boolean;
    model: Model;
    imageModel: ImageModel;
};

export const useSettings = () => {
    const { user, updateUser } = useAuthorization();
    const { models, imageModels } = useConfig();

    const formState: FormData = useMemo(
        () => ({
            botDisabled: user?.settingBotDisabled ?? false,
            communicatesInEntry: user?.settingCommunicatesInEntry ?? false,
            context: user?.settingContext ?? false,
            imageModel: user?.settingImageModel ?? ImageModel.DallE3,
            model: user?.settingModel ?? Model.Gpt3,
        }),
        [
            user?.settingBotDisabled,
            user?.settingCommunicatesInEntry,
            user?.settingContext,
            user?.settingModel,
            user?.settingImageModel,
        ]
    );

    const { patch } = useFetch<UpdateSettingsResponseDto, User>({
        endpoint: 'user/settings',
    });
    const form = useForm<FormData>({
        defaultValues: formState,
    });
    const toast = useToast();

    const { reset } = form;

    const handleSubmit = async (data: FormData) => {
        try {
            const user = await patch({
                body: data,
                mapResponse: mapUserResponse,
            });
            updateUser(user);

            toast({
                duration: 5000,
                status: 'success',
                title: 'Zapisano ustawienia',
            });
        } catch {}
    };

    useEffect(() => {
        if (user) {
            reset(formState);
        }
    }, [user, reset, formState]);

    return {
        form,
        handleSubmit,
        imageModels,
        models,
        user,
    };
};
