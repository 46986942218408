import { useToast } from '@chakra-ui/react';
import { useCallback, useState } from 'react';

import {
    InternalServerError,
    UnauthorizedError,
    UnprocessableEntity,
    deleteAccessToken,
    getAccessToken,
} from '../utils';

export type ResponseError =
    | 'Unauthorized'
    | 'UnprocessableEntity'
    | 'ServerError';

type Params = {
    endpoint: string;
    cache?: boolean;
};

type RequestParams<TResponse, TData> = {
    body?: unknown;
    followRedirect?: boolean;
    method: 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'PUT';
    pathParams?: Record<string, string>;
    queryParams?: Record<string, string>;
    mapResponse?: (response: TResponse) => TData;
};

type GetParams<TResponse, TData> = {
    queryParams?: Record<string, string>;
} & Pick<
    RequestParams<TResponse, TData>,
    'followRedirect' | 'mapResponse' | 'pathParams' | 'queryParams'
>;

type PatchParams<TResponse, TData> = Pick<
    RequestParams<TResponse, TData>,
    'mapResponse' | 'body' | 'pathParams' | 'queryParams'
>;

type PutParams<TResponse, TData> = PatchParams<TResponse, TData>;
type PostParams<TResponse, TData> = PatchParams<TResponse, TData>;
type DeleteParams<TResponse, TData> = Pick<
    RequestParams<TResponse, TData>,
    'pathParams' | 'queryParams'
>;

export const useFetch = <TResponse, TData>({
    cache = false,
    endpoint,
}: Params) => {
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    const request = useCallback(
        async <TResponse, TData>({
            body,
            followRedirect,
            method = 'GET',
            pathParams,
            mapResponse,
            queryParams,
        }: RequestParams<TResponse, TData>): Promise<TData> => {
            try {
                setLoading(true);

                const url = new URL(endpoint, process.env.REACT_APP_API_URL);

                Object.entries(queryParams ?? []).forEach(([key, value]) => {
                    url.searchParams.append(key, value);
                });

                let endpointWithParams = url.toString();
                Object.entries(pathParams ?? []).forEach(([key, value]) => {
                    endpointWithParams = endpointWithParams.replace(
                        new RegExp(`:${key}`, 'gi'),
                        value
                    );
                });

                const response = await fetch(endpointWithParams, {
                    body: JSON.stringify(body),
                    headers: {
                        Authorization: `Bearer ${getAccessToken()}`,
                        'Content-Type': 'application/json',

                        ...(!cache && {
                            'Cache-Control':
                                'no-cache, no-store, must-revalidate',
                            Expires: '0',
                            Pragma: 'no-cache',
                        }),
                    },
                    method,
                });

                if (response.status === 302 || followRedirect) {
                    window.location.href = response.url;
                }

                if (response.status === 401) {
                    throw new UnauthorizedError();
                }

                if (response.status >= 400 && response.status < 500) {
                    throw new UnprocessableEntity();
                }

                if (response.status >= 500) {
                    throw new InternalServerError();
                }

                const parsed = await response.json();
                const data = mapResponse ? mapResponse(parsed) : parsed;

                return data;
            } catch (error) {
                if (error instanceof UnauthorizedError) {
                    deleteAccessToken();
                    toast({
                        duration: 5000,
                        status: 'info',
                        title: 'Utracono sesję użytkownika. Zaloguj się ponownie',
                    });
                }
                throw error;
            } finally {
                setLoading(false);
            }
        },
        [cache, endpoint, toast]
    );

    const get = useCallback(
        async (params: GetParams<TResponse, TData> = {}) => {
            const { followRedirect, mapResponse, pathParams, queryParams } =
                params;

            return await request<TResponse, TData>({
                followRedirect,
                mapResponse,
                method: 'GET',
                pathParams,
                queryParams,
            });
        },
        [request]
    );

    const patch = useCallback(
        async ({
            body,
            mapResponse,
            pathParams,
            queryParams,
        }: PatchParams<TResponse, TData> = {}) => {
            return await request<TResponse, TData>({
                body,
                mapResponse,
                method: 'PATCH',
                pathParams,
                queryParams,
            });
        },
        [request]
    );

    const post = useCallback(
        async ({
            body,
            mapResponse,
            pathParams,
            queryParams,
        }: PostParams<TResponse, TData> = {}) => {
            return await request<TResponse, TData>({
                body,
                mapResponse,
                method: 'POST',
                pathParams,
                queryParams,
            });
        },
        [request]
    );

    const put = useCallback(
        async ({
            body,
            mapResponse,
            pathParams,
            queryParams,
        }: PutParams<TResponse, TData> = {}) => {
            return await request<TResponse, TData>({
                body,
                mapResponse,
                method: 'PUT',
                pathParams,
                queryParams,
            });
        },
        [request]
    );

    const remove = useCallback(
        async ({
            pathParams,
            queryParams,
        }: DeleteParams<TResponse, TData> = {}) => {
            return await request<TResponse, TData>({
                method: 'DELETE',
                pathParams,
                queryParams,
            });
        },
        [request]
    );

    return {
        delete: remove,
        get,
        loading,
        patch,
        post,
        put,
    };
};
