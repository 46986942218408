import { ImageModel, Model, Role, UserMeResponseDto } from '../../api/types';
import { User } from '../../types';
import { ResponseError } from '../errors';

export const mapUserResponse = (response: UserMeResponseDto): User => {
    if (!response.data) {
        throw new ResponseError();
    }

    const { data } = response;

    return {
        disabled: !!data.disabled ?? false,
        id: data.id ?? 0,
        role: data.role ?? Role.Standard,
        settingBotDisabled: data.settingBotDisabled ?? false,
        settingCommunicatesInEntry: data.settingCommunicatesInEntry ?? false,
        settingContext: data.settingContext ?? false,
        settingImageModel: data.settingImageModel ?? ImageModel.None,
        settingModel: data.settingModel ?? Model.Gpt3,
        tokens: data.tokens ?? 0,
        username: data.username ?? '',
    };
};
