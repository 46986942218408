import { Heading, VStack } from '@chakra-ui/react';

import { Markdown } from '../../components';

import texts from './resources/texts';

export const FAQ = () => {
    return (
        <VStack alignItems="stretch" gap="32px">
            <Heading as="h1">FAQ</Heading>
            <Markdown>{texts.faq}</Markdown>
        </VStack>
    );
};
