import { useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

import {
    GetImageBase64ResponseDto,
    GetImagesResponseDto,
} from '../../api/types';
import { useFetch } from '../../hooks';
import { Image } from '../../types';
import { mapImageBase64Response, mapImagesResposne } from '../../utils';

export const useImages = () => {
    const toast = useToast();
    const [images, setImages] = useState<Image[]>([]);
    const { get: fetchImages } = useFetch<GetImagesResponseDto, Image[]>({
        endpoint: 'images',
    });
    const { get: fetchImage, loading } = useFetch<
        GetImageBase64ResponseDto,
        string
    >({
        endpoint: 'images/:id',
    });

    const getImages = useCallback(async () => {
        try {
            const images = await fetchImages({
                mapResponse: mapImagesResposne,
            });
            setImages(images);
        } catch (error) {
            toast({
                duration: 5000,
                status: 'error',
                title: 'Wystąpił problem z pobraniem listy obrazów.',
            });
        }
    }, [fetchImages, toast]);

    const downloadImage = async (id: number) => {
        try {
            const base64 = await fetchImage({
                mapResponse: mapImageBase64Response,
                pathParams: {
                    id: id.toString(),
                },
            });

            const path = `data:image/png;base64,${base64}`;
            const link = document.createElement('a');
            link.href = path;

            link.download = `${new Date().getTime()}.png`;

            link.click();
            link.remove();
        } catch (error) {
            toast({
                duration: 5000,
                status: 'error',
                title: 'Wystąpił problem z pobraniem grafiki.',
            });
        }
    };

    useEffect(() => {
        getImages();
    }, [getImages]);

    return {
        downloadImage,
        images,
        loading,
    };
};
