import { useToast } from '@chakra-ui/react';
import {
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { UserMeResponseDto } from '../api/types';
import { useFetch } from '../hooks';
import { User } from '../types';
import {
    deleteAccessToken,
    getAccessToken,
    setAccessToken,
    mapUserResponse,
} from '../utils';

const AuthorizationContext = createContext<{
    authorized: boolean;
    user: User | null;
    logout: () => void;
    login: (token: string) => void;
    updateUser: (data: Partial<User>) => void;
}>({
    authorized: false,
    login: () => null,
    logout: () => null,
    updateUser: () => null,
    user: null,
});

type AuthorizationProviderProps = PropsWithChildren<unknown>;

export const AuthorizationProvider = ({
    children,
}: AuthorizationProviderProps) => {
    const [authorized, setAuthorized] = useState(!!getAccessToken());
    const [user, setUser] = useState<User | null>(null);
    const { get } = useFetch<UserMeResponseDto, User>({
        endpoint: 'user/me',
    });
    const toast = useToast();

    const login = useCallback((token: string) => {
        setAccessToken(token);
        setAuthorized(true);
    }, []);

    const logout = useCallback(() => {
        deleteAccessToken();
        setAuthorized(false);
    }, []);

    const updateUser = useCallback(
        (data: Partial<User>) => {
            if (user) {
                setUser({ ...user, ...data });
            }
        },
        [user]
    );

    const fetchMe = useCallback(async () => {
        try {
            const data = await get({ mapResponse: mapUserResponse });
            setUser(data);
        } catch {
            toast({
                duration: 5000,
                status: 'error',
                title: 'Wystąpił problem z pobraniem danych użytkownika. Odśwież stronę i zaloguj się ponownie.',
            });
        }
    }, [get, toast]);

    useEffect(() => {
        if (authorized) {
            fetchMe();
        }
    }, [authorized, fetchMe]);

    const value = useMemo(
        () => ({ authorized, login, logout, updateUser, user }),
        [authorized, login, logout, updateUser, user]
    );

    return (
        <AuthorizationContext.Provider value={value}>
            {children}
        </AuthorizationContext.Provider>
    );
};

export const useAuthorization = () => {
    return useContext(AuthorizationContext);
};
