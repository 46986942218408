import {
    CircularProgress,
    Heading,
    Link,
    Text,
    VStack,
} from '@chakra-ui/react';
import Markdown from 'react-markdown';

import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '../../components';
import { usePlatform } from '../../hooks';
import { fillPlaceholders } from '../../utils';

import { Prompt } from './Prompt';
import texts from './resources/texts';
import { useImages } from './useImages';

export const Images = () => {
    const { isDesktop } = usePlatform();
    const { images, loading, downloadImage } = useImages();

    return (
        <VStack alignItems="stretch" gap="32px">
            <Heading as="h1">Wygenerowane obrazy</Heading>
            <Markdown>
                {fillPlaceholders(texts.description, { time: '2' })}
            </Markdown>
            <TableContainer>
                <Table>
                    <Thead>
                        <Tr>
                            <Th width={isDesktop ? '200px' : undefined}>
                                Data utworzenia
                            </Th>
                            <Th>Prompt</Th>
                            <Th isNumeric>Link do pobrania</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {images.map(({ id, prompt, date }) => (
                            <Tr key={id}>
                                <Td>
                                    <Text>
                                        {date.format('YYYY-MM-DD HH:mm:ss')}
                                    </Text>
                                </Td>
                                <Td>
                                    <Prompt>{prompt}</Prompt>
                                </Td>
                                <Td
                                    isNumeric
                                    width={isDesktop ? '210px' : undefined}
                                >
                                    <Link onClick={() => downloadImage(id)}>
                                        {loading ? (
                                            <CircularProgress
                                                isIndeterminate
                                                color="blue.400"
                                                size="20px"
                                            />
                                        ) : (
                                            <Text>Pobierz</Text>
                                        )}
                                    </Link>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </VStack>
    );
};
