import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Switch,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Controller, FormProvider } from 'react-hook-form';

import { Select, SelectItem } from '../../../../components';
import { User } from '../../../../types';

import { FormData, useUserEditModal } from './useUserEditModal';

export type UserEditModalProps = {
    user: User | null;
    onClose: () => void;
};

export const UserEditModal = ({ onClose, user }: UserEditModalProps) => {
    const { form, roles, models, imageModels, loading, handleSubmit } =
        useUserEditModal({ onClose, user });

    const open = !!user;
    const { register } = form;

    return (
        <Modal isOpen={open} onClose={onClose}>
            <ModalOverlay />
            <ModalContent background="gray.800">
                <ModalHeader>Edycja: {user?.username}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(handleSubmit)}>
                            <VStack gap="32px">
                                <FormControl
                                    alignItems="flexStart"
                                    display="flex"
                                    gap="24px"
                                >
                                    <Controller<FormData>
                                        name="disabled"
                                        render={({
                                            field: { value, onBlur, onChange },
                                        }) => (
                                            <Switch
                                                id="account-disabled-switch"
                                                isChecked={!!value}
                                                onBlur={onBlur}
                                                onChange={onChange}
                                            />
                                        )}
                                    />

                                    <FormLabel
                                        htmlFor="account-disabled-switch"
                                        mb="0"
                                    >
                                        <Text>Konto zablokowane</Text>
                                    </FormLabel>
                                </FormControl>
                                <FormControl
                                    alignItems="flexStart"
                                    display="flex"
                                    flexDirection="column"
                                    gap="24px"
                                >
                                    <FormLabel htmlFor="tokens-input" mb="0">
                                        <Text>Tokeny</Text>
                                    </FormLabel>
                                    <Input
                                        id="tokens-input"
                                        maxWidth={{
                                            base: '100%',
                                            lg: '300px',
                                        }}
                                        type="number"
                                        {...register('tokens')}
                                    />
                                </FormControl>
                                <FormControl
                                    alignItems="flexStart"
                                    display="flex"
                                    flexDirection="column"
                                    gap="24px"
                                >
                                    <FormLabel htmlFor="model-select" mb="0">
                                        <Text>Rola</Text>
                                    </FormLabel>
                                    <Select
                                        id="role-select"
                                        {...register('role')}
                                    >
                                        {roles.map((role) => (
                                            <SelectItem key={role} value={role}>
                                                {role}
                                            </SelectItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl
                                    alignItems="flexStart"
                                    display="flex"
                                    flexDirection="column"
                                    gap="24px"
                                >
                                    <FormLabel htmlFor="model-select" mb="0">
                                        <Text>Model tekstowy</Text>
                                    </FormLabel>
                                    <Select
                                        id="model-select"
                                        {...register('model')}
                                    >
                                        {models.map((model) => (
                                            <SelectItem
                                                key={model}
                                                value={model}
                                            >
                                                {model}
                                            </SelectItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl
                                    alignItems="flexStart"
                                    display="flex"
                                    flexDirection="column"
                                    gap="24px"
                                >
                                    <FormLabel
                                        htmlFor="image-model-select"
                                        mb="0"
                                    >
                                        <Text>Model obrazkowy</Text>
                                    </FormLabel>
                                    <Select
                                        id="image-model-select"
                                        {...register('imageModel')}
                                    >
                                        {imageModels.map((model) => (
                                            <SelectItem
                                                key={model}
                                                value={model}
                                            >
                                                {model}
                                            </SelectItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </VStack>
                        </form>
                    </FormProvider>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="transparent" mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button
                        disabled={loading}
                        type="submit"
                        onClick={form.handleSubmit(handleSubmit)}
                    >
                        Zapisz
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
