import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';

import { AuthorizationProvider, ConfigProvider } from './components';
import { Main } from './Main';
import { theme } from './theme';

export const App = () => (
    <BrowserRouter>
        <ChakraProvider theme={theme}>
            <AuthorizationProvider>
                <ConfigProvider>
                    <Main />
                </ConfigProvider>
            </AuthorizationProvider>
        </ChakraProvider>
    </BrowserRouter>
);
