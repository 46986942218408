import { Heading, VStack } from '@chakra-ui/react';

import { Markdown } from '../../components';

import texts from './resources/texts';

export const About = () => {
    return (
        <VStack alignItems="stretch" gap="32px">
            <Heading as="h1">Informacje o projekcie</Heading>
            <Markdown>{texts.about}</Markdown>
        </VStack>
    );
};
