import { useToast } from '@chakra-ui/react';
import {
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import { AppConfigResponseDto } from '../api/types';

import { useFetch } from '../hooks';

import { Config } from '../types';

import { mapConfigResponse } from '../utils';

import { useAuthorization } from './AuthorizationContext';

const initialConfig: Config = {
    imageModels: [],
    models: [],
};

const ConfigContext = createContext<Config>(initialConfig);

export type ConfigProviderProps = PropsWithChildren<unknown>;

export const ConfigProvider = ({ children }: ConfigProviderProps) => {
    const toast = useToast();
    const { authorized } = useAuthorization();
    const [config, setConfig] = useState(initialConfig);

    const { get } = useFetch<AppConfigResponseDto, Config>({
        endpoint: 'app-config',
    });

    const fetchConfig = useCallback(async () => {
        try {
            const data = await get({ mapResponse: mapConfigResponse });
            setConfig(data);
        } catch {
            toast({
                duration: 5000,
                status: 'error',
                title: 'Wystąpił problem z pobraniem konfiguracji. Niektóre funkcjonalności mogą nie działać poprawnie.',
            });
        }
    }, [get, toast]);

    useEffect(() => {
        if (authorized) {
            fetchConfig();
        }
    }, [authorized, fetchConfig]);

    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
};

export const useConfig = () => {
    return useContext(ConfigContext);
};
