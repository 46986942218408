import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthorization } from './AuthorizationContext';

type ProtectedRouteProps = PropsWithChildren<unknown>;

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
    const { authorized } = useAuthorization();

    if (!authorized) {
        return <Navigate to="/" />;
    }

    return <>{children}</>;
};
