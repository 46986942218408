import { useToast } from '@chakra-ui/toast';
import { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';

import { UserSearchResponseDto } from '../../../../api/types';
import { useFetch } from '../../../../hooks';
import { User } from '../../../../types';
import { mapUserSearchResponse } from '../../../../utils';

const debounceDelay = 800;

export type FormData = {
    disabled: boolean;
};

export const useUsersPanel = () => {
    const toast = useToast();
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [username, setUsername] = useState('');
    const [users, setUsers] = useState<User[]>([]);
    const methods = useForm();
    const { get } = useFetch<UserSearchResponseDto, User[]>({
        endpoint: 'user/search',
    });

    const debouncedSearchUsers = useDebouncedCallback(
        async (username: string) => {
            try {
                const users = await get({
                    mapResponse: mapUserSearchResponse,
                    queryParams: { username: username },
                });

                setUsers(users);
            } catch {
                toast({
                    duration: 5000,
                    status: 'error',
                    title: 'Wystąpił problem z wyszukaniem uzytkownika',
                });
            }
        },
        debounceDelay
    );

    const handleUsernameChange = async (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = event.target;

        setUsername(value);

        if (value.length > 1) {
            await debouncedSearchUsers(value);
        }
    };

    const handleUserToEditClick = (user: User) => {
        setSelectedUser(user);
    };

    const handleModalClose = () => {
        setSelectedUser(null);
    };

    return {
        handleModalClose,
        handleUserToEditClick,
        handleUsernameChange,
        methods,
        selectedUser,
        username,
        users,
    };
};
