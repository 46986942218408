import { Button, HStack, Text } from '@chakra-ui/react';

import { supportLink } from '../config';
import { usePlatform } from '../hooks';

export const SupportBar = () => {
    const { isDesktop } = usePlatform();

    const handleClick = () => {
        window.location.href = supportLink;
    };

    return (
        <HStack
            gap="24px"
            justifyContent="center"
            marginBottom={{ base: '0', lg: '100px' }}
            padding="20px"
        >
            {isDesktop ? (
                <Text color="green.300">
                    Wspomóż Projekt i odbierz nowe tokeny, dostęp do modelu
                    GPT-4 Turbo oraz Dalle-3!
                </Text>
            ) : (
                <Text color="green.300">Wsparcie projektu</Text>
            )}
            <Button
                backgroundColor="green.600"
                colorScheme="green"
                width={100}
                onClick={handleClick}
            >
                Klik
            </Button>
        </HStack>
    );
};
