import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { sessionStorageTokenKey } from '../config';

import { useAuthorization } from './AuthorizationContext';

export const Login = () => {
    const [searchParams] = useSearchParams();
    const { login } = useAuthorization();
    const navigate = useNavigate();

    useEffect(() => {
        const token = searchParams.get(sessionStorageTokenKey);

        if (token) {
            login(token);
            navigate('/');
        }
    }, [login, navigate, searchParams]);

    return null;
};
