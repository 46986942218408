import {
    Heading,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    VStack,
} from '@chakra-ui/react';

import { UsersPanel } from './panels/users-panel/UsersPanel';

export const AdminPanel = () => {
    return (
        <VStack alignItems="stretch" gap="32px">
            <Heading as="h1">Panel administracyjny</Heading>
            <Tabs colorScheme="blue">
                <TabList>
                    <Tab>Użytkownicy</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel padding="0">
                        <UsersPanel />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </VStack>
    );
};
