import {
    Button,
    FormControl,
    FormLabel,
    Heading,
    Switch,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Controller, FormProvider } from 'react-hook-form';

import { Role } from '../../api/types';
import { ReactComponent as IconSave } from '../../assets/icons/icon-disc.svg';

import { Markdown, Select, SelectItem } from '../../components';

import { usePlatform } from '../../hooks';

import texts from './resources/texts';
import { type FormData, useSettings } from './useSettings';

export const Settings = () => {
    const { isDesktop } = usePlatform();
    const { handleSubmit, imageModels, form, models, user } = useSettings();

    const { register } = form;

    const disabled = user?.role === Role.Standard;

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <VStack alignItems="flex-start" gap="32px">
                    <Heading as="h1">Ustawienia</Heading>
                    {disabled && (
                        <Text color="red.400" fontWeight="normal">
                            UWAGA: Niektóre ustawienia są dostępne jedynie dla
                            wspierających projekt.
                        </Text>
                    )}
                    <FormControl
                        alignItems="flexStart"
                        display="flex"
                        gap="24px"
                    >
                        <Controller<FormData>
                            name="communicatesInEntry"
                            render={({
                                field: { value, onBlur, onChange },
                            }) => (
                                <Switch
                                    id="communicates-in-entry-switch"
                                    isChecked={!!value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                        />

                        <FormLabel
                            htmlFor="communicates-in-entry-switch"
                            mb="0"
                        >
                            <Markdown>{texts.alertSwitchSetting}</Markdown>
                        </FormLabel>
                    </FormControl>
                    <FormControl
                        alignItems="flexStart"
                        display="flex"
                        gap="24px"
                    >
                        <Controller<FormData>
                            name="botDisabled"
                            render={({
                                field: { value, onBlur, onChange },
                            }) => (
                                <Switch
                                    id="bot-disabled-switch"
                                    isChecked={!!value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                        />

                        <FormLabel htmlFor="bot-disabled-switch" mb="0">
                            <Markdown>{texts.botDisabledSetting}</Markdown>
                        </FormLabel>
                    </FormControl>

                    <FormControl
                        alignItems="flexStart"
                        display="flex"
                        flexDirection="column"
                        gap="24px"
                    >
                        <FormLabel htmlFor="model-select" mb="0">
                            <Markdown>{texts.modelSelectSetting}</Markdown>
                        </FormLabel>
                        <Select
                            disabled={disabled}
                            id="model-select"
                            {...register('model')}
                        >
                            {models.map((model) => (
                                <SelectItem key={model} value={model}>
                                    {model}
                                </SelectItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        alignItems="flexStart"
                        display="flex"
                        flexDirection="column"
                        gap="24px"
                    >
                        <FormLabel htmlFor="model-select" mb="0">
                            <Markdown>{texts.imageModelSelectSetting}</Markdown>
                        </FormLabel>
                        <Select
                            disabled={disabled}
                            id="image-model-select"
                            {...register('imageModel')}
                        >
                            {imageModels.map((model) => (
                                <SelectItem key={model} value={model}>
                                    {model}
                                </SelectItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        alignSelf={isDesktop ? 'flex-end' : 'stretch'}
                        leftIcon={
                            <IconSave fill="#000" height="24px" width="24px" />
                        }
                        type="submit"
                    >
                        Zapisz ustawienia
                    </Button>
                </VStack>
            </form>
        </FormProvider>
    );
};
