import { Heading, Link, Text, VStack } from '@chakra-ui/react';
import { AnchorHTMLAttributes, HTMLAttributes } from 'react';
import ReactMarkdown from 'react-markdown';

export type MarkdownProps = {
    children: string;
};

const components = {
    a: ({ children, href }: AnchorHTMLAttributes<HTMLAnchorElement>) => (
        <Link color="blue.400" href={href}>
            {children}
        </Link>
    ),
    h2: ({ children }: HTMLAttributes<HTMLElement>) => (
        <Heading as="h2" fontWeight={300} marginY="24px" size="lg">
            {children}
        </Heading>
    ),
    h3: ({ children }: HTMLAttributes<HTMLElement>) => (
        <Heading as="h2" fontWeight={300} marginY="8px" size="md">
            {children}
        </Heading>
    ),
    p: ({ children }: HTMLAttributes<HTMLElement>) => (
        <Text as="p" fontWeight={300}>
            {children}
        </Text>
    ),
    strong: ({ children }: HTMLAttributes<HTMLElement>) => (
        <Text as="strong" fontWeight={500}>
            {children}
        </Text>
    ),
};

export const Markdown = ({ children }: MarkdownProps) => {
    return (
        <VStack alignItems="flex-start" gap="8px">
            <ReactMarkdown components={components}>{children}</ReactMarkdown>
        </VStack>
    );
};
