import { useToast } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import {
    ImageModel,
    Model,
    Role,
    UpdateUserBodyDto,
} from '../../../../api/types';
import { useFetch } from '../../../../hooks';
import { User } from '../../../../types';

export type FormData = {
    disabled: boolean;
    role: Role;
    model: Model;
    imageModel: ImageModel;
    tokens: number;
};

type Params = {
    user: User | null;
    onClose: () => void;
};

export const useUserEditModal = ({ onClose, user }: Params) => {
    const formState: FormData = useMemo(
        () => ({
            disabled: user?.disabled ?? false,
            imageModel: user?.settingImageModel ?? ImageModel.None,
            model: user?.settingModel ?? Model.Gpt3,
            role: user?.role ?? Role.Standard,
            tokens: user?.tokens ?? 0,
        }),
        [
            user?.disabled,
            user?.settingImageModel,
            user?.settingModel,
            user?.role,
            user?.tokens,
        ]
    );

    const toast = useToast();
    const form = useForm<FormData>({ defaultValues: formState });
    const { loading, patch } = useFetch<UpdateUserBodyDto, User>({
        endpoint: 'user/edit/:id',
    });

    const { reset } = form;

    const handleSubmit = async (data: FormData) => {
        if (!user?.id) {
            return;
        }

        try {
            const body: UpdateUserBodyDto = {
                ...data,
                tokens: Number(data.tokens),
            };
            await patch({ body, pathParams: { id: user.id.toString() } });
            onClose();
            toast({
                duration: 5000,
                status: 'success',
                title: 'Pomyślnie zaaktualizowano dane użytkownika.',
            });
        } catch {
            toast({
                duration: 5000,
                status: 'error',
                title: 'Błąd podczas aktualizacji danych użytkownika.',
            });
        }
    };

    useEffect(() => {
        if (user) {
            reset(formState);
        }
    }, [user, reset, formState]);

    const roles = Object.values(Role);
    const models = Object.values(Model);
    const imageModels = Object.values(ImageModel);

    return {
        form,
        handleSubmit,
        imageModels,
        loading,
        models,
        roles,
    };
};
