import dayjs from 'dayjs';

import { GetImagesResponseDto } from '../../api/types';
import { Image } from '../../types';

export const mapImagesResposne = (response: GetImagesResponseDto): Image[] => {
    return (
        response?.data?.map((image) => ({
            date: dayjs(image.date),
            id: image.id ?? 0,
            prompt: image.prompt ?? '',
        })) ?? []
    );
};
