import { Text } from '@chakra-ui/react';

type PromptProps = {
    children: string;
};

const maxSigns = 255;

export const Prompt = ({ children }: PromptProps) => {
    const text =
        children.length > maxSigns
            ? `${children.slice(0, maxSigns)}...`
            : children;

    return <Text sx={{ textWrap: 'wrap' }}>{text}</Text>;
};
