import { Theme, useMediaQuery } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';

export const usePlatform = () => {
    const theme = useTheme() as Theme;

    const [isDesktop] = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);

    return {
        isDesktop,
        isMobile: !isDesktop,
    };
};
