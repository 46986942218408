import { Grid, GridItem, SystemStyleObject } from '@chakra-ui/react';
import { Routes, Route } from 'react-router-dom';

import {
    Login,
    Footer,
    HamburgerMenu,
    Menu,
    ProtectedRoute,
    SupportBar,
} from './components';
import { usePlatform } from './hooks';
import { About, AdminPanel, FAQ, Settings, Images } from './pages';

export const Main = () => {
    const { isDesktop } = usePlatform();

    const menuGridItemStyles: SystemStyleObject = {
        ...(!isDesktop && {
            backgroundColor: 'gray.800',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'sticky',
            top: '0',
            zIndex: 1,
        }),
    };

    const contentGridItemStyles: SystemStyleObject = {
        marginBottom: '64px',
        ...(!isDesktop && {
            paddingX: '20px',
        }),
    };

    return (
        <Grid
            columnGap={{ base: '0px', lg: '64px' }}
            margin={{ base: undefined, lg: '0 auto' }}
            minHeight="100vh"
            templateAreas={{
                base: `
                    "menu"
                    "content"
                    "footer"
                `,
                lg: `
                "support-bar support-bar"
                "menu content"
                "footer footer"
              `,
            }}
            templateColumns={{ base: '1fr', lg: '260px 1fr' }}
            templateRows={{ base: '64px 1fr auto', lg: 'auto 1fr auto' }}
            width={{ base: 'auto', lg: '1200px' }}
        >
            {isDesktop && (
                <GridItem gridArea="support-bar">
                    <SupportBar />
                </GridItem>
            )}
            <GridItem gridArea="menu" sx={menuGridItemStyles}>
                {isDesktop ? (
                    <Menu />
                ) : (
                    <>
                        <SupportBar />
                        <HamburgerMenu />
                    </>
                )}
            </GridItem>
            <GridItem gridArea="content" sx={contentGridItemStyles}>
                <Routes>
                    <Route element={<About />} path="/" />
                    <Route element={<FAQ />} path="/faq" />
                    <Route
                        element={
                            <ProtectedRoute>
                                <Images />
                            </ProtectedRoute>
                        }
                        path="/images"
                    />
                    <Route
                        element={
                            <ProtectedRoute>
                                <Settings />
                            </ProtectedRoute>
                        }
                        path="/settings"
                    />
                    <Route
                        element={
                            <ProtectedRoute>
                                <AdminPanel />
                            </ProtectedRoute>
                        }
                        path="/admin"
                    />
                    <Route element={<Login />} path="/token" />
                </Routes>
            </GridItem>
            <GridItem gridArea="footer">
                <Footer />
            </GridItem>
        </Grid>
    );
};
