import { Th as ChakraTh, TableCellProps } from '@chakra-ui/react';

import { usePlatform } from '../../hooks';

export const Th = (props: TableCellProps) => {
    const { isDesktop } = usePlatform();

    return (
        <ChakraTh
            padding={isDesktop ? undefined : '12px'}
            sx={{ textWrap: 'wrap' }}
            {...props}
        />
    );
};
