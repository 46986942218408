import { Input, SystemStyleObject, Text, VStack } from '@chakra-ui/react';

import { ReactComponent as IconPencil } from '../../../../assets/icons/icon-pencil.svg';
import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '../../../../components';

import { UserEditModal } from './UserEditModal';
import { useUsersPanel } from './useUsersPanel';

const rowStyles: SystemStyleObject = {
    '&:hover': {
        backgroundColor: 'gray.600',
    },
    transition: '0.3s background-color',
};

export const UsersPanel = () => {
    const {
        username,
        users,
        handleUsernameChange,
        handleModalClose,
        handleUserToEditClick,
        selectedUser,
    } = useUsersPanel();

    return (
        <VStack alignItems="flex-start" gap="32px" marginTop="24px">
            <Input
                placeholder="Wyszukaj użytkownika po nazwie"
                size="lg"
                type="search"
                value={username}
                onChange={handleUsernameChange}
            />

            <TableContainer>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Nazwa</Th>
                            <Th>Rola</Th>
                            <Th>Tokeny</Th>
                            <Th>Model chata</Th>
                            <Th>Model obrazkowy</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {users.map((user) => {
                            const {
                                id,
                                username,
                                role,
                                tokens,
                                settingModel,
                                settingImageModel,
                            } = user;
                            return (
                                <Tr
                                    key={id}
                                    cursor="pointer"
                                    sx={rowStyles}
                                    onClick={() => handleUserToEditClick(user)}
                                >
                                    <Td>
                                        <Text>{id}</Text>
                                    </Td>
                                    <Td>
                                        <Text>{username}</Text>
                                    </Td>
                                    <Td>
                                        <Text>{role}</Text>
                                    </Td>
                                    <Td>
                                        <Text>{tokens}</Text>
                                    </Td>
                                    <Td>
                                        <Text>{settingModel}</Text>
                                    </Td>
                                    <Td>
                                        <Text>{settingImageModel}</Text>
                                    </Td>
                                    <Td width="50px">
                                        <IconPencil
                                            height="24px"
                                            width="24px"
                                        />
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
            {!users.length && <Text>Nie znaleziono użytkowników</Text>}
            <UserEditModal user={selectedUser} onClose={handleModalClose} />
        </VStack>
    );
};
