import {
    useToken,
    Select as ChakraSelect,
    SelectProps as ChakraSelectProps,
} from '@chakra-ui/react';
import { PropsWithChildren, forwardRef } from 'react';

export type SelectProps = PropsWithChildren<ChakraSelectProps>;

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
    ({ children, ...props }, ref) => {
        return (
            <ChakraSelect
                ref={ref}
                backgroundColor="gray.800"
                id="model-select"
                maxWidth={{ base: '100%', lg: '300px' }}
                {...props}
            >
                {children}
            </ChakraSelect>
        );
    }
);

Select.displayName = 'Select';

export type SelectItemProps = {
    children: string;
    value: string;
};

export const SelectItem = ({ children, value }: SelectItemProps) => {
    const optionColor = useToken('colors', 'gray.200');
    const optionBackgroundColor = useToken('colors', 'gray.900');

    const optionStyle = {
        backgroundColor: optionBackgroundColor,
        color: optionColor,
    };

    return (
        <option style={optionStyle} value={value}>
            {children}
        </option>
    );
};
